import { ref } from 'vue';
import { projectFunctions } from '../firebase/config';

const error = ref(null);
const isPending = ref(false);

const tenantApproval = async (signUpCode) => {
    error.value = null;
    isPending.value = true;
    try {
        const approval = projectFunctions.httpsCallable("tenantApproval");
        const res = await approval({ signUpCode });
        if (res.data.error) {
            error.value = res.data.error;
        }
        isPending.value = false;

    } catch (err) {
        error.value = `Approval Error: ${err.message}`;
        isPending.value = false;
    }
};

const useFunctions = () => {
    return { error, tenantApproval, isPending };
};

export default useFunctions;