import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/auth/Login.vue';
import SignUp from '../views/auth/SignUp.vue';
import getUser from "@/composables/getUser";
import useLogout from "@/composables/useLogout";

// route guard 
const requireAuth = (to, from, next) => {
  const { user, tenant } = getUser();
  const loginPath = window.location.pathname;
  if (!user.value) {
    next({ name: 'Login', query: { from: loginPath } });
  } else {
    if (!tenant.value) {
      const { logout } = useLogout();
      logout().then(() => {
        console.log("Logging out the non-tenant user");
        next({ name: 'Login', query: { from: loginPath } });
      });
    } else {
      next();
    }
  }
};

const requireNoAuth = async (to, from, next) => {
  const { user, tenant } = getUser();
  if (user.value) {
    if (tenant.value) {
      next({ name: 'Home' });
    } else {
      const { logout } = useLogout();
      logout().then(() => {
        console.log("Logging out the non-tenant user");
        next({ name: 'Login' });
      });
    }
  } else {
    next();
  }
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: requireNoAuth,
  },
  {
    path: '/signUp',
    name: 'SignUp',
    component: SignUp,
    beforeEnter: requireNoAuth,
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
