<template>
  <main>
    <form @submit.prevent="handleSubmit" class="shadow">
      <h3>Tenant Sign Up</h3>
      <div class="form-outline mb-4">
        <input
          type="text"
          placeholder="Display Name"
          v-model="displayName"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <input
          type="email"
          placeholder="Email"
          v-model="email"
          class="form-control"
        />
      </div>
      <div v-if="isPasswordShowing" class="input-group mb-4">
        <input
          type="text"
          placeholder="Password"
          v-model="password"
          class="form-control"
        />
        <span
          class="input-group-text btn btn-primary"
          @click="isPasswordShowing = false"
          ><i class="bi bi-eye-slash"></i>
        </span>
      </div>
      <div v-else class="input-group mb-4">
        <input
          type="password"
          placeholder="Password"
          v-model="password"
          class="form-control"
        />
        <span
          class="input-group-text btn btn-primary"
          @click="isPasswordShowing = true"
          ><i class="bi bi-eye"></i>
        </span>
      </div>
      <div class="form-outline mb-4">
        <input
          type="text"
          placeholder="Sign Up Code"
          v-model="signUpCode"
          class="form-control"
          required
        />
      </div>
      <div class="pb-3">
        I have agreed to these
        <span class="btn-link s-link p-0" @click="showingTerms = true">
          Terms and Privacy Policy
        </span>
      </div>
      <div v-if="formError" class="error">{{ formError }}</div>
      <div
        v-if="!isPending"
        class="d-flex justify-content-between align-items-center"
      >
        <button class="btn btn-primary btn-block">Sign Up</button>
        <div>
          Already a member?
          <router-link
            :to="{ name: 'Login', query: { from: route.query.from } }"
            class="float-end ms-1"
          >
            Sign In</router-link
          >
        </div>
      </div>
      <button v-else class="btn btn-secondary btn-block" disabled>
        <div class="spinner-border" role="status"></div>
      </button>
    </form>
  </main>
</template>

<script>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import useSignUp from "@/composables/useSignUp";
import useFunctions from "@/composables/useFunctions";
import useCollection from "@/composables/useCollection";
import getUser from "@/composables/getUser";
import { timestamp } from "../../firebase/config";
import useLogout from "@/composables/useLogout";

export default {
  setup() {
    const isPasswordShowing = ref(false);
    const { error: memberError, addUniqueId } = useCollection("users");
    const email = ref("");
    const signUpCode = ref("");
    const password = ref("");
    const displayName = ref("");
    const formError = ref(null);
    const isPending = ref(false);

    const router = useRouter();
    const route = useRoute();
    const { error: signUpError, signUp } = useSignUp();
    const { error: tenantError, tenantApproval } = useFunctions();
    const { setTenant } = getUser();
    const { logout, error: errorLogout } = useLogout();

    const handleSubmit = async () => {
      formError.value = "";
      isPending.value = true;
      try {
        await tenantApproval(signUpCode.value);
        console.log("SignUp: ", tenantError.value);
        if (!tenantError.value) {
          const res = await signUp(
            email.value,
            password.value,
            displayName.value
          );
          if (!signUpError.value) {
            await addUniqueId(res.user.uid, {
              role: "tenant",
              name: displayName.value,
              email: email.value,

              createdAt: timestamp()
            });
            setTenant(true);
            isPending.value = false;
            if (!memberError.value) {
              //logout
              await logout();
              if (!errorLogout.value) {
                router.push({ name: "Home" });
              } else {
                formError.value("Error logging out: ", errorLogout.value);
              }
            } else {
              formError.value = memberError.value;
            }
          } else {
            formError.value = signUpError.value;
          }
        } else {
          console.log("APPROVAL ERROR: ", tenantError.value);
          formError.value = "Sign Up Code is Invalid";
        }
        isPending.value = false;
      } catch (err) {
        formError.value = "Error Signing Up: " + err.message;
        isPending.value = false;
      }
    };

    return {
      email,
      password,
      isPasswordShowing,
      displayName,
      signUpCode,
      formError,
      isPending,
      handleSubmit,
      route
    };
  }
};
</script>

<style>
</style>