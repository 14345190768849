// Note Firebase SDK 9 changes
import firebase from 'firebase/compat/app';
// SDK 9: import { initializeApp } from '@firebase/app';
import 'firebase/compat/firestore';
// SDK 9: import { getFirestore } from '@firebase/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/functions';

const firebaseConfig = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID
};

// init firebase
firebase.initializeApp(firebaseConfig);
// SDK 9: const firebaseApp = initializeApp(firebaseConfig);

// init firestore service
const projectFirestore = firebase.firestore();
// SDK 9: const projectFirestore = getFirestore(firebaseApp);
const projectAuth = firebase.auth();
const projectFunctions = firebase.functions();

// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp;


export { projectFirestore, projectAuth, projectFunctions, timestamp };