import { ref } from 'vue';
import { projectAuth } from '../firebase/config';

// refs
const user = ref(projectAuth.currentUser);
const tenant = ref(false);

// auth changes
projectAuth.onAuthStateChanged(_user => {
  user.value = _user;
  if (user.value) {
    user.value.getIdTokenResult().then((getIdTokenResult) => {
      if (getIdTokenResult.claims.tenant) {
        tenant.value = true;
      } else {
        tenant.value = false;
      }
    });
  }
});

// set role
const setTenant = (value) => {
  tenant.value = value;
};

const getUser = () => {
  return { user, tenant, setTenant };
};

export default getUser;